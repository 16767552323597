import { Component , Inject , isDevMode , enableProdMode} from '@angular/core';
import * as mqtt from 'mqtt';
// import { DOCUMENT } from '@angular/common';
import {config} from './config'
import {log} from './common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Vastra Web';
  client = mqtt.connect(config.mqttConfig);
  isConnectionAvaliable = false;
  constructor(){
    var _this = this;
    _this.client.on('connect', function () {
      log('Mqtt Server Connected')
      _this.isConnectionAvaliable = true;
    });
    _this.client.on('message', function (topic, message) {
      log("Topic :: ",topic," ::: Message ::: ",message.toString());
    });    
  }
  publish(message = "Default"){
    log('Publish Called  '+this.title);
    
    this.client.publish('fooBar',message,function (err){
      log('Errr ::::: ',err);
    });
  }
  subscribe(topic="default",callback){
    log('Publish Called  '+topic);
    this.client.subscribe(topic, function (err) {
      if(!err){
        callback(true);
      }else{
        callback(false);
      }
    });
  }
  unsubscribe(topic="default",callback){
    log('Publish Called  '+topic);
    this.client.unsubscribe(topic, function (err) {
      if(!err){
        callback(true);
      }else{
        callback(false);
      }
    });
  }
}
