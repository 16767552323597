import { 
  Component, 
  ElementRef,
  Input, 
  Renderer2, 
  AfterViewInit, 
  HostListener, 
  OnInit} from '@angular/core';

import { document, window } from '../utils/facade/browser';

@Component({
  selector: 'pmd-sidebar, [pmd-sidebar]',
  templateUrl: 'sidebar.component.html',
  host: {
    'class': 'pmd-sidebar pmd-z-depth',
    '[class.pmd-sidebar-open]': 'isOpen',
    '[class.pmd-sidebar-right-fixed]': 'placement == "right" && position == "fixed"',
    '[class.pmd-sidebar-bottom-fixed]': 'placement == "bottom" && position == "fixed"',
    '[class.pmd-sidebar-left]': 'placement == "left"',
    '[class.pmd-sidebar-slide-push]': 'position == "slidepush"',
    '[class.pmd-sidebar-left-fixed]': 'position == "fixed" && placement == "left"',
  }
})

export class PmdSidebar implements AfterViewInit, OnInit {

  // backdrop = this.renderer.createElement('div');

  @Input() placement: string = '';
  @Input() position: string = '';
  @Input() isOpenWidth: number;
  @Input() isOpen: boolean;
  @Input() overlay: boolean = true;

  isSidebarOpen: boolean

  div = this.renderer.createElement('div');
  text = this.renderer.createText('');
  parent = this._elementRef.nativeElement.parentNode;
  refChild = this._elementRef.nativeElement;
  body = document.getElementsByTagName('body')[0];
  
  constructor(public renderer: Renderer2, private _elementRef: ElementRef) {
    this.performTask();
    this.toggleClassBody();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const navli= this._elementRef.nativeElement.querySelectorAll('a.nav-link');
    if(!this._elementRef.nativeElement.classList.contains("pmd-sidebar-slide-push")) {
      if (this.isOpen){
        this.addClassOverly();
      } else {
        this.removeClassOverly();
      }
    }
    if (window.innerWidth < this.isOpenWidth) {
      this.hideSidebarisOpenWidth();
    } else {
      this.showSidebarisOpenWidth();
    }
    this.div.addEventListener('click', this._clickHandler);
    this.toggleClassBody();
  }


  clickHandler(event: any) {
    this.removeClassOverly();
    this.body.classList.remove("pmd-body-open");
    this.isOpen = false;
  }
  _clickHandler = this.clickHandler.bind(this);

  toggleSidebarOverlay(): void{
    this.removeClassOverly();
    this.isOpen = false;
   // this.toggleClassBody();
  }

  toggleSideBar(): void {
    this.isOpen = !this.isOpen;
    this.toggleClassBody();
    if(!this._elementRef.nativeElement.classList.contains("pmd-sidebar-slide-push")) {
      if (this.isOpen){
        this.addClassOverly();
      } else {
       this.removeClassOverly();
      }
    }
  }

  toggleClassBody(){
    if (this.isOpen){
      this.body.classList.add("pmd-body-open");
    } else {
      this.body.classList.remove("pmd-body-open");
    }
  }

  performTask() {
    this.renderer.addClass(this.div, 'pmd-sidebar-overlay');
    this.renderer.appendChild(this.div, this.text);
    this.renderer.insertBefore(this.parent, this.div, this.refChild);
 }

  addClassOverly(){
    if (this.overlay){
      this.renderer.addClass(this.div, 'pmd-sidebar-overlay-active');
    }
 // this.renderer.addClass(this.renderer.selectRootElement('.pmd-sidebar-overlay'), 'pmd-sidebar-overlay-active');
  }
  
  removeClassOverly(){
    this.renderer.removeClass(this.div, 'pmd-sidebar-overlay-active');
  // this.renderer.removeClass(this.renderer.selectRootElement('.pmd-sidebar-overlay'), 'pmd-sidebar-overlay-active');
  }

  addClassSlidepush(){
    this._elementRef.nativeElement.classList.add("pmd-sidebar-slide-push");
  }

  removeClassSlidepush(){
    this._elementRef.nativeElement.classList.remove("pmd-sidebar-slide-push");
  }

  addClassOpen(){
    this._elementRef.nativeElement.classList.add("pmd-sidebar-open");
  }
  removeClassOpen(){
    this._elementRef.nativeElement.classList.remove("pmd-sidebar-open");
  }

  addClassLeftFixed(){
    this._elementRef.nativeElement.classList.add("pmd-sidebar-left-fixed");
  }

  removeClassLeftFixed(){
    this._elementRef.nativeElement.classList.remove("pmd-sidebar-left-fixed");
  }

  addClassIsSlidepush(){
    this._elementRef.nativeElement.classList.add("is-slidepush");
  }
  
  removeClassIsSlidepush(){
    this._elementRef.nativeElement.classList.remove("is-slidepush");
  }

  hideSlidepush(){
    this.removeClassSlidepush();
    this.isOpen = false;
    this.addClassLeftFixed();
    this.body.classList.remove("pmd-body-open");
  }

  showSlidepush(){
    this.addClassSlidepush();
    this.isOpen = true;
    this.removeClassLeftFixed();
    this.body.classList.add("pmd-body-open");
  }

  hideSidebarisOpenWidth(){
    const navli= this._elementRef.nativeElement.querySelectorAll('a.nav-link');
    if (!this._elementRef.nativeElement.classList.contains("is-slidepush")){
      this.addClassIsSlidepush();
      if (this.position == "slidepush" && this.placement == "left") {
        this.hideSlidepush();
      }
      if (this.position == "fixed") {
        if ( this.placement == "left" || this.placement == "right") {
          this.isOpen = false;
          this.removeClassOverly();
        }
      }  
    }
    for (const button of navli) {
      button.addEventListener('click', this._clickHandler)
    }
  }

  showSidebarisOpenWidth(){
    const navli= this._elementRef.nativeElement.querySelectorAll('a.nav-link');
    if(this._elementRef.nativeElement.classList.contains("is-slidepush")){
      this.removeClassIsSlidepush();
      if (this.position == "slidepush" && this.placement == "left") {
        this.showSlidepush();
      }
      if (this.isOpen = true){
        if (this.position == "fixed") {
          if ( this.placement == "left" || this.placement == "right") {
            this.isOpen = true;
            this.addClassOverly();
          }
        }
      }
    }
    for (const button of navli) {
      button.removeEventListener('click', this._clickHandler)
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    if (!this._elementRef.nativeElement.classList.contains("is-slidepush")){
      if(this._elementRef.nativeElement.classList.contains("pmd-sidebar-slide-push")) {
        this.removeClassOverly();
      }
    }

    if (event.target.innerWidth < this.isOpenWidth) {
      this.hideSidebarisOpenWidth();
    } else {
      this.showSidebarisOpenWidth();
    }

  }
}