import {
  Component,
  ViewEncapsulation,
  Directive
} from '@angular/core';


/**
 * Content of a card.
 */
@Directive({
  selector: 'pmd-card-body',
  host: {
	  'class': 'card-body',
	},
})
export class PmdCardBody {}

/**
 * Title of a card.
 */
@Directive({
  selector: `pmd-card-title, [pmd-card-title], [pmdCardTitle]`,
  host: {
    'class': 'card-title'
  }
})
export class PmdCardTitle {}

/**
 * Sub-title of a card.
 */
@Directive({
  selector: `pmd-card-subtitle, [pmd-card-subtitle], [pmdCardSubtitle]`,
  host: {
    'class': 'card-subtitle'
  }
})
export class PmdCardSubtitle {}

/**
 * card-text of a card.
 */
@Directive({
  selector: `pmd-card-text, [pmd-card-text], [pmdCardText]`,
  host: {
    'class': 'card-text'
  }
})
export class PmdCardText {}


/**
 * Footer of a card.
 */
@Directive({
  selector: 'pmd-card-footer',
  host: {
	  'class': 'card-footer',
  }
})
export class PmdCardFooter {}

/**
 * Image used in a card.
 */
@Directive({
  selector: '[pmd-media-list], [pmdMediaList]',
  host: {'class': 'media'}
})
export class pmdMediaList {}

/**
 * Image used in a card.
 */
@Directive({
  selector: '[pmd-card-image], [pmdCardImage]',
  host: {'class': 'pmd-card-media'}
})
export class PmdCardImage {}

/**
 * Avatar image used in a card.
 */
@Directive({
  selector: '[pmd-card-avatar], [pmdCardAvatar]',
  host: {'class': 'pmd-avatar-list-img'}
})
export class PmdCardAvatar {}


/**
 * A basic content container component that adds the styles of a pmd material design card.
 */
@Component({
  selector: 'pmd-card',
  exportAs: 'pmdCard',
  templateUrl: 'card.html',
  styleUrls: ['./pmd-card.css'],
  host: {'class': 'card pmd-card'},
  encapsulation: ViewEncapsulation.None,
})
export class PmdCard {}

/**
 * Component intended to be used within the `<pmd-card>` component. It adds styles for a
 * preset header section (i.e. a title, subtitle, and avatar layout).
 */
@Component({
  selector: 'pmd-card-header',
  templateUrl: 'card-header.html',
  encapsulation: ViewEncapsulation.None,	
  host: {
	  'class': 'card-header',
  }
})
export class PmdCardHeader {}


/**
 * Component intended to be used within the `<pmd-card>` component. It adds styles for a preset
 * layout that groups an image with a title section.
 */
@Component({
  selector: 'pmd-card-header-media',
  templateUrl: 'card-media.html',
  encapsulation: ViewEncapsulation.None,	
  host: {'class': 'card-header media'}
})
export class PmdCardHeaderMedia {}
