import { NgModule, ModuleWithProviders } from '@angular/core';

import { PmdModalBackdrop } from './modal-backdrop.component';
import { PmdModal } from './modal.directive';
import { PositioningService } from '../positioning';
import { ComponentLoaderFactory } from '../component-loader';
import { PmdModalContainer } from './modal-container.component';
import { PmdModalService } from './pmd-modal.service';

@NgModule({
  declarations: [
    PmdModalBackdrop,
    PmdModal,
    PmdModalContainer
  ],
  exports: [PmdModalBackdrop, PmdModal],
  entryComponents: [PmdModalBackdrop, PmdModalContainer]
})
export class PmdModalModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PmdModalModule,
      providers: [PmdModalService, ComponentLoaderFactory, PositioningService]
    };
  }
}
