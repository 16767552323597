import { environment } from '../environments/environment';

export const config = { // TWENTY20
  production: false,
  apiUrl: !environment.production ? "http://111.93.65.238:3001/api/v2/" : "https://vastraapp.com/api/v2/",
  apiIp: !environment.production ? "http://111.93.65.238:3001/" : "https://vastraapp.com/" ,
  designUrl: !environment.production ? "http://111.93.65.238:3001/api/v2/other/get-media/4/" : "https://vastraapp.com/api/v2/other/get-media/4/",
  mqttConfig: !environment.production ? "mqtt://111.93.65.238:1884" : "wss://vastraapp.com:8884",
  apiKey: !environment.production ? "1" : "va$Tra@pP",
  defaultDesignImage: "assets/images/designs/default_design.png",
  defaultSupplierImage: "assets/images/suppliers/default_supplier.png",
  firstTopic: "$VASTRA@!QRCODE$",
  lastStoreTopic: "STOREQRCODE",
  helpCenterLink: "https://vastraapp.com/#contact",
  AWS: {
    AWS_ACCESS_KEY: !environment.production ? "AKIAI7GMPMBODVQWZ33Q" : "AKIAJDISJVMKK5KY6NAA",
    AWS_SECRET_ACCESS_KEY: !environment.production ? "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" : "7wa4KXyshPFh1ibD725/xotB9Gs80RBuIQLKEbHZ",
    signatureVersion: !environment.production ? "v4" : "v4",
    region: !environment.production ? "ap-south-1" : "ap-south-1",
    generateQrLambda: !environment.production ? "qrGenerator" : "qrGenerator"
  },
  mediaType: {
    user: 1,
    karigar: 2,
    customer: 3,
    design: 4,
    staff: 5,
    organization: 6,
    supplier: 7,
    agent: 8,
    jobslip: 9,
    material: 10,
    purchaseinward: 11,
    staffIdProof: 12,
    creditNotes: 13,
    purchaseReturn: 14,
    purchaseInwardChallan: 15,
    purchaseOrder: 16,
    jobslipNotes: 17,
    orderDesignNotes: 18,
    orderMaterialNotes: 19,
    purchaseOrderDesignNotes: 20,
    purchaseOrderMaterialNotes: 21,
    designQRCode: 41,
    otherOrganization: 22,
    packingslipDesignNotes: 23,
    packingslipMaterialNotes: 24,
    defaultOrganizationType: 25,
    defaultVideoContent: 26,
    packingslipLRImages: 27,
    orderReturnDesignNotes: 28,
    orderReturnMaterialNotes: 29
  },
  recordStatus: {
    Deleted: 0,
    Active: 1,
    Hidden: 3
  },

  genType: {
    DESIGN: 1,
    MATERIAL: 2
  },
  stockInOutType : {
    EXISTING_STOCK: 1, //In (Existing Stock User Can Add)
    PURCHASE_INWARD: 2, //In (User Add Purchase Inwards)
    PACKING_ADD: 3, //In (Mandi(Packaging)- Packing Manager Packed Items THEN STOCK ADDED THAT)
    PACKING_SLIP: 4, //Out (When Packingslip Created)
    MATERIAL_USE_IN_JOBSLIP: 5, //Out (Material Added In JobSlip)
    MATERIAL_RETURN_FROM_JOBSLIP: 6, //In (Material Added In JobSlip)
    READY_TO_PACK: 7, //In (When user click ready for pack at jobslip recieve time) (dressType wise)
    READY_TO_PACK_PACKED: 8, //Out (When packing manager packed items (Mandi) at that time out entry will fire) (dressType wise)
    BILL_CREATED: 9, // Out Stock out entry after Bill Generate
    JOBSLIP_READY_TO_DISPATCH: 10, // In
    PURCHASE_INWARD_RETURN: 11, // Out
    ORDER_RETURN: 12 // In
}

};
