import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PmdSidebar} from './sidebar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    PmdSidebar 
  ],
  exports: [
    PmdSidebar
  ],
  providers: []
})

export class SidebarModule {}
