import { isDevMode } from '@angular/core';
import Swal from 'sweetalert2';


export function log(message?: any, ...optionalParams: any[]) {
    if (isDevMode()) {
        console.log(message);
        for (let i = 0; i < optionalParams.length; i++) {
            console.log(optionalParams[i]);
        }
    }
};
export function getLocalData(key=null) {
    let localData = JSON.parse(localStorage.getItem('userData'));
    return (!key) ? localData : localData[key];
};
export function getUniqueString(){
    return Number(new Date())+'_'+Math.random().toString(36).toString().replace(".", "");
}

export function somethingWentWrongError(){
    Swal.fire({
        title: 'Oops...',
        text: 'Something went wrong!',
        icon: 'error'
    });
}

export function noInternet(){
    Swal.fire({
        title: 'No Internet...',
        text: 'You are not connected with internet !',
        icon: 'question'
    });
}

export function errorPopup(title,text){
    Swal.fire({
        title: title,
        text: text,
        icon: 'error'
    });
}

export function successPopup(title,text){
    Swal.fire({
        title: title,
        text: text,
        icon: 'success'
    });
}


export function checkGstIsValid(gstNum){
    return /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(gstNum)
}

export function checkPanIsValid(panNum){
    return /^[A-Z]{5}\d{4}[A-Z]$/.test(panNum)
}

export function checkEmailIsValid(email){
    if(email.length > 255){
        return false;
    }
    return /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/.test(email);
}

function replaceNullToStringNull(key, val){
    return val == null ? "NULL" : val;
}
export function convertNullToStringNull(obj){
    return JSON.parse(JSON.stringify(obj, replaceNullToStringNull))
}
  
export default {log};
// export logs;
