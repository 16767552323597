import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeLayoutComponent } from './layout/home-layout/home-layout.component';
// import { LoginComponent } from './auth/login/login.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth",
    data: { title: "Initial Load" },
    pathMatch: "full"
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then(mod => mod.AuthModule)
  },
  {
    path: "reports",
    loadChildren: () => import("./reports/reports.module").then(mod => mod.ReportsModule)
  },
  {
    path: "more",
    loadChildren: () => import("./more/more.module").then(mod => mod.MoreModule)
  },
  {
    path: '404', 
    component: NotFoundComponent
    // path: "404",
    // component: HomeLayoutComponent,
    // data: { title: "First Component" },
    // children: [
    //   {
    //     path: "",
    //     component: NotFoundComponent,
    //     data: { title: "Not Found" }
    //   }
    // ]
  },
  {  
    path: '**', 
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
