import {Component} from '@angular/core';

@Component({
  selector: 'pmd-navbar-right-icon',
  template: `<ng-content></ng-content>`,
  host:{
    class:'pmd-navbar-right-icon ml-auto'
  }
})

export class PmdNavbarRightIcon {}
