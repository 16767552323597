import { CommonModule} from '@angular/common';
import { NgModule} from '@angular/core';
import { PmdNavbar} from './navbar.component';
import { PmdNavbarRightIcon } from './navbar-right-icon.component';
import { PmdNavbarCollapse } from './links.component';
import { PmdNavbarBrand } from './logo.component';
import { PmdNavbarService } from './navbar.service';

@NgModule({
  
  imports: [CommonModule],
  
  declarations: [
    PmdNavbar, 
    PmdNavbarCollapse, 
    PmdNavbarBrand,
    PmdNavbarRightIcon, 
  ],

  exports: [
    PmdNavbar, 
    PmdNavbarCollapse, 
    PmdNavbarBrand,
    PmdNavbarRightIcon, 
  ],

  providers: [PmdNavbarService]
  
})

export class PmdNavbarModule {}
