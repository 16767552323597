import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';

import { PmdNavbarModule } from "./components/navbars";
import { PmdCardModule } from "./components/card";
import { HeaderComponent } from "./shared/header/header.component";


/* Development Start */
import { HttpModule } from "@angular/http";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SidebarModule } from './components/sidebar';
import { PmdModalModule } from './components/modal';
import { ToastrModule } from 'ngx-toastr';

/* Refresh Not Working */
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PmdNavbarModule,
    PmdCardModule,     
    HttpClientModule,
    HttpModule,
    BrowserAnimationsModule,
    SidebarModule,
    PmdModalModule.forRoot(),
    ToastrModule.forRoot({
      progressBar : true,
      progressAnimation : 'decreasing',
      positionClass: 'toast-top-right',
      timeOut: 5000
    })

  ],
  providers: [],
  // providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
